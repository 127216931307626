import React, { useState } from "react";
import clsx from "clsx";
import { Link, navigate } from "gatsby";
import favicon from "../images/favicon.ico";
import Helmet from "react-helmet";

const Layout = ({ children, title, sidebar }) => {
  const defaultPageTitle = "林蔭下教育：新界和離島學校的故事";
  const fullPageTitle = title
    ? `${defaultPageTitle} - ${title}`
    : defaultPageTitle;

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleMobileMenuClick = (url) => {
    navigate(url);
    setSidebarVisible(false);
  };

  return (
    <div>
      <Helmet
        title={fullPageTitle}
        link={[
          {
            rel: "icon",
            type: "image/x-icon",
            href: favicon,
          },
        ]}
      />
      <div class="fixed z-10 px-20 h-16 top-0 left-0 right-0 border-b bg-white lg:flex hidden items-center justify-between nav-top">
        <div className="flex items-center">
          <ul class="flex items-center">
            <li>
              <svg
                class="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </li>
            <li>
              <Link to="/">首頁</Link>
            </li>
            {title && title !== defaultPageTitle && (
              <li>
                <div>
                  <span className="text-gray-400 mx-2">/</span>
                  <span className="text-gray-400">{title}</span>
                </div>
              </li>
            )}
          </ul>
        </div>
        <a
          href="https://www.chunghwabook.com.hk/book/detail/id/1378029545429737884"
          target="_blank"
          rel="noreferrer"
        >
          出版社: 中華書局
          <svg
            class="w-6 h-6 inline-block ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
            ></path>
          </svg>
        </a>
      </div>
      <div className="sidebar w-1/5 lg:flex hidden lg:flex-col">
        <div className="site-title h-16 text-white flex items-center justify-center">
          林蔭下教育：新界和離島學校的故事
        </div>
        <div class="flex-1 flex justify-center flex-col items-center">
          <Link to="/#main" className="nav-item">
            首頁
          </Link>
          <Link to="/#intro" className="nav-item">
            本書簡介
          </Link>
          <Link to="/#ack" className="nav-item">
            鳴謝
          </Link>
          <Link to="/#team" className="nav-item">
            編著及製作團隊
          </Link>
          <Link to="/#interviews" className="nav-item">
            專訪錄影
          </Link>
          <Link to="/#disclaimer" className="nav-item">
            免責聲明
          </Link>
        </div>
        <div class="text-center text-white py-5 px-5 text-sm font-thin">
          <p>
            李子建、鄭保瑛、鄧穎瑜、黃詠筠、陳君堯、
            <br />
            香港教育大學香港教育博物館 —— 編著
          </p>
          <p>李子建、鄭保瑛、鄧穎瑜 —— 主編</p>
        </div>
      </div>
      <div className="fixed z-10 px-4 h-16 top-0 left-0 right-0 bg-theme-primary lg:hidden flex items-center justify-between">
        <h1 className="text-white">林蔭下教育：新界和離島學校的故事</h1>
        <button
          class="focus:outline-none text-white"
          onClick={() => setSidebarVisible(true)}
        >
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
      <div
        className={clsx(
          "fixed z-10 inset-0 bg-gray-300 bg-opacity-50",
          sidebarVisible ? "" : "hidden"
        )}
      ></div>
      <div
        className={clsx(
          "lg:hidden transform fixed top-0 right-0 w-10/12 h-full z-20 transition duration-300",
          sidebarVisible ? "" : "translate-x-full"
        )}
      >
        <div className="sidebar flex flex-col">
          <div className="text-white p-4 flex justify-between items-center">
            <button
              type="button"
              className="text-white"
              onClick={() => setSidebarVisible(false)}
            >
              <svg
                class="w-10 h-10"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            <a
              href="https://www.chunghwabook.com.hk/book/detail/id/1378029545429737884"
              target="_blank"
              rel="noreferrer"
            >
              出版社: 中華書局
              <svg
                class="w-6 h-6 inline-block ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                ></path>
              </svg>
            </a>
          </div>
          <div class="flex-1 flex justify-center flex-col items-center">
            <button
              onClick={() => handleMobileMenuClick("/#main")}
              className="nav-item"
            >
              首頁
            </button>
            <button
              onClick={() => handleMobileMenuClick("/#intro")}
              className="nav-item"
            >
              本書簡介
            </button>
            <button
              onClick={() => handleMobileMenuClick("/#ack")}
              className="nav-item"
            >
              鳴謝
            </button>
            <button
              onClick={() => handleMobileMenuClick("/#team")}
              className="nav-item"
            >
              編著及製作團隊
            </button>
            <button
              onClick={() => handleMobileMenuClick("/#interviews")}
              className="nav-item"
            >
              專訪錄影
            </button>
            <button
              onClick={() => handleMobileMenuClick("/#disclaimer")}
              className="nav-item"
            >
              免責聲明
            </button>
          </div>
          <div class="text-center text-white py-5 px-5 text-sm font-thin">
            <p>
              李子建、鄭保瑛、鄧穎瑜、黃詠筠、陳君堯、
              <br />
              香港教育大學香港教育博物館 —— 編著
            </p>
            <p>李子建、鄭保瑛、鄧穎瑜 —— 主編</p>
          </div>
        </div>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Layout;